import React, { PureComponent } from 'react';
import Card from './Card.js'
import Break from './Break.js'
import { Grid, Row, Col } from 'react-flexbox-grid';
import Styles from '../../src/styles/StyleGuide.js'
import { ResponsiveContainer, LineChart, Line, XAxis, YAxis, ReferenceLine, ReferenceArea,
    ReferenceDot, Tooltip, CartesianGrid, Legend, Brush, ErrorBar, AreaChart, Area,
    Label, LabelList } from 'recharts';

const data = [
    { name: 'Page A', uv: 1000, pv: 2400, amt: 2400, uvError: [75, 20] },
    { name: 'Page B', uv: 300, pv: 4567, amt: 2400, uvError: [90, 40] },
    { name: 'Page C', uv: 280, pv: 1398, amt: 2400, uvError: 40 },
    { name: 'Page D', uv: 200, pv: 9800, amt: 2400, uvError: 20 },
    { name: 'Page E', uv: 278, pv: null, amt: 2400, uvError: 28 },
    { name: 'Page F', uv: 189, pv: 4800, amt: 2400, uvError: [90, 20] },
    { name: 'Page G', uv: 189, pv: 4800, amt: 2400, uvError: [28, 40] },
    { name: 'Page H', uv: 189, pv: 4800, amt: 2400, uvError: 28 },
    { name: 'Page I', uv: 189, pv: 4800, amt: 2400, uvError: 28 },
    { name: 'Page J', uv: 189, pv: 4800, amt: 2400, uvError: [15, 60] },
  ];

const Charts = (props) => {
    return (
        <div>
            <Break />
            <Grid fluid>
                <Row>
                    <Col lg={4} md={6} sm={12} >
                        <Card level="_7">
                            <h5>This is a test</h5>
                            
                                <ResponsiveContainer width={'99%'} height={250}>
                                <LineChart
                                    width={400}
                                    height={250}
                                    data={data}
                                    margin={{ top: 5, right: 20, left: 0, bottom: 5 }}>
                                    <XAxis axisLine={false} tickLine={false} dataKey="name" />
                                    <YAxis axisLine={false} tickLine={false} type="number" />
                                    <Tooltip />
                                    <Line type="monotone"
                                            strokeWidth={3}
                                            dataKey="uv"
                                            stroke={Styles.primary}
                                            yAxisId={0} />
                                    <Line type="monotone"
                                            strokeWidth={3}
                                            dataKey="pv"
                                            stroke={Styles.secondary}
                                            />
                                </LineChart>
                                </ResponsiveContainer>
                            
                        </Card>
                    </Col>
                    <Col lg={4} md={6} sm={12}>
                        <Card level="_7">
                            <h5>This is a test</h5>
                            
                                <ResponsiveContainer width={'99%'} height={250}>
                                <LineChart
                                    width={400}
                                    height={250}
                                    data={data}
                                    margin={{ top: 5, right: 20, left: 0, bottom: 5 }}>
                                    <XAxis axisLine={false} tickLine={false} dataKey="name" />
                                    <YAxis axisLine={false} tickLine={false} type="number" />
                                    <Tooltip />
                                    <Line type="monotone"
                                            strokeWidth={3}
                                            dataKey="uv"
                                            stroke={Styles.primary}
                                            yAxisId={0} />
                                    <Line type="monotone"
                                            strokeWidth={3}
                                            dataKey="pv"
                                            stroke={Styles.secondary}
                                            />
                                </LineChart>
                                </ResponsiveContainer>
                            
                        </Card>
                    </Col>
                    <Col lg={4} md={6} sm={12}>
                        <Card level="_7">
                            <h5>This is a test</h5>
                            
                                <ResponsiveContainer width={'99%'} height={250}>
                                <LineChart
                                    width={400}
                                    height={250}
                                    data={data}
                                    margin={{ top: 5, right: 20, left: 0, bottom: 5 }}>
                                    <XAxis axisLine={false} tickLine={false} dataKey="name" />
                                    <YAxis axisLine={false} tickLine={false} type="number" />
                                    <Tooltip />
                                    <Line type="monotone"
                                            strokeWidth={3}
                                            dataKey="uv"
                                            stroke={Styles.primary}
                                            yAxisId={0} />
                                    <Line type="monotone"
                                            strokeWidth={3}
                                            dataKey="pv"
                                            stroke={Styles.secondary}
                                            />
                                </LineChart>
                                </ResponsiveContainer>
                            
                        </Card>
                    </Col>
                </Row>
            </Grid> 
        </div>

    )
}

export default Charts;