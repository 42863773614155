import React from 'react';
import Card from './Card'
import Break from './Break'
import {ButtonPrimary, ButtonSecondary, ButtonTertiary} from './Buttons.tsx'
import { Grid, Row, Col } from 'react-flexbox-grid';
import {Text} from './Typography.tsx'
import {Dropdown, DropdownItem} from './Dropdown.tsx';

const About = (props) => {
    return (
        <div>
            <Grid fluid>
                <Row>
                    <Col className="parent" lg={6}>
                        <Card level="_1">
                            <h5>Buttons</h5>
                            <Row>
                                <Col lg={6} md={6}>
                                    <Text size="sm" len="short">
                                        This is a primary button:
                                    </Text>
                                </Col>
                                <Col lg={6} md={6}>
                                    <ButtonPrimary>Test</ButtonPrimary>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={6} md={6}>
                                    <Text size="sm" len="short">
                                        This is a Secondary button:
                                    </Text>
                                </Col>
                                <Col lg={6} md={6}>
                                    <ButtonSecondary>Test</ButtonSecondary>
                                </Col>
                            </Row>
                            <Row>
                                <Col lg={6} md={6}>
                                    <Text size="sm" len="short">
                                        Tertiary button:
                                    </Text>
                                </Col>
                                <Col lg={6} md={6}>
                                    <ButtonTertiary>Click me</ButtonTertiary>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                    <Col className="parent" lg={6}>
                        <Card level="_1">
                            <h5>Controllers</h5>
                            <Row>
                                <Col lg={6} md={6}>
                                    <Text size="lg" len="short">
                                        This is a dropdown
                                    </Text>
                                </Col>
                                <Col lg={6} md={6}>
                                    <Dropdown title="click me">
                                        <DropdownItem onClick={()=>alert("hi")}>
                                            Test
                                        </DropdownItem>
                                        <DropdownItem>Test</DropdownItem>
                                        <DropdownItem>Test</DropdownItem>
                                    </Dropdown>
                                </Col>
                            </Row>
                        </Card>
                    </Col>
                </Row>
            </Grid> 
            <Break/>
            <Break/><Break/>
        </div>

    )
}

export default About;