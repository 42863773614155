import React from 'react';
import Card from './Card.js'
import Break from './Break.js'
import { Grid, Row, Col } from 'react-flexbox-grid';
import TaskScreen from '../containers/taskScreen'

const Home = (props) => {
    return (
        <div>
            <Break />
            <Grid fluid>
                <Row>
                    <Col lg={6}>
                        <Card level="_1">
                            <h5>Testing Redux integration</h5>
                            <TaskScreen></TaskScreen>
                        </Card>
                    </Col>
                    <Col lg={6}>
                        <Row>
                            <Col lg={12}>
                                <Card level="_4">
                                    <p>Hello</p>
                                </Card>
                            </Col>
                            <Col className="parent" lg={12}>
                                <Card level="_9">
                                    <p>These are cards</p>
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Grid> 
        </div>

    )
}

export default Home;