import React from 'react';
import {Link} from "react-router-dom";
import logo from '../img/marque_bw.svg';
import {Dropdown, DropdownItem} from './Dropdown.tsx';
import {ButtonTertiary} from './Buttons.tsx'

const Navbar = () => {
    return (
        <nav>
            <div className="_9 nav">
                <Link className="nav-logo" to="/">
                    <img src={logo} alt="white-logo.svg"></img>
                </Link>
                <div className="btn-group">
                    <Link className="btn btn-tertiary white" to="/">Home</Link>
                    <Link className="btn btn-tertiary white" to="/components">Components</Link>
                    <Link className="btn btn-tertiary white" to="/charts">Charts</Link>
                    <Dropdown id="nav-dropdown" title={<i className='fad fa-user-circle'></i>}> 
                        <DropdownItem>testing the test for testing purposes</DropdownItem> 
                        <DropdownItem>test</DropdownItem> 
                    </Dropdown>
                    <ButtonTertiary id="menu-popout">
                        <Link className="btn btn-tertiary white" to="/mobile_menu">
                            <i className="fas fa-bars"></i>
                        </Link>
                    </ButtonTertiary>
                </div>
            </div>
        </nav>
      )
}

export default Navbar