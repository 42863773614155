import React, {useState} from 'react';


const DropdownItem: React.FC<{onClick: () => void, children: React.ReactNode}> = (props) => {
    return (
        <span onClick={ props.onClick && (e => props.onClick()) } className="_7 body-short-1 dropdown__item">{props.children}</span>
    )
}


const Dropdown: React.FC<{
    id: string
    title: string
    children: React.ReactNode
}> = (props) => {
    let [isOpen, setIsOpen] = useState(false)
    const toggleHandler = () => {
        setIsOpen(isOpen ? false : true)
    }

    return (
        <div className="dropdown--container">
            <button id={props.id} onClick={toggleHandler} className="dropdown btn btn-secondary">
                <span className="dropdown__title">
                    {props.title}
                    { isOpen ? <i className="fas fa-angle-up"></i> : <i className="fas fa-angle-down"></i> }
                </span>
            </button>

            <div className="dropdownItems__container">
                { isOpen && props.children }
            </div>
        </div>
    )
}


export {Dropdown, DropdownItem};