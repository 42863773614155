import React from 'react';

const Card = (props) => {
    return (
        <div className={`card ${props.level} ${props.justify ? 'justify' : ''}`}>
            {props.children}
        </div>
    )
}

export default Card;