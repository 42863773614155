const initialState =  {
    todos: ["Hi there!"]
}

const testReducer = (state = initialState, action) => {
    switch (action.type) {
        case "ADD_TODO":
            state = {
                ...state,
                todos: [...state.todos, action.payload]
            };
            break;
        default:
            return state
    }
    return state;
};

export default testReducer;