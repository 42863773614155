import React from 'react'
import { connect } from 'react-redux'
import * as actions from '../actions/testActions';

const TaskScreen = (props) => {
    const addHandler = () => {
        let text = prompt("enter a distinct blah")
        props.addTodo(text)
    }
    return (
        <div>
            <ul>
                {   props.todos &&
                    props.todos.map((todo, index)=>{
                        return <li key={index}>{todo}</li>
                    })
                }
            </ul>
            <button className="btn btn-primary" onClick={addHandler}>Add todo</button>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        todos: state.testReducer.todos
    }
}

const mapDispatchToProps = dispatch => {
    return {
        addTodo: (task) => {
            dispatch(actions.addTodo(task))
        }
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(TaskScreen)
