import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";
import './styles/App.css';
import Navbar from './components/Navbar.js'
import Home from './components/Home.js'
import Components from './components/Components.js'
import Charts from './components/Charts.js'


const App = () => {
  let query = new URLSearchParams(window.location.search);

  return (
      <Router>
        <Navbar></Navbar>
        <div className="home">
          <Switch>
              <Route exact path="/">
                <Home />
              </Route>
              <Route path="/components">
                <Components test={query.get("test")} />
              </Route>
              <Route path="/charts">
                <Charts />
              </Route>
              <Route path="/dashboard">
                <p>Dashboard</p>
              </Route>
              <Route path="/mobile_menu">
                <p>Testing the mobile menu</p>
              </Route>
          </Switch>
        </div>
      </Router>

  );
}

export default App;